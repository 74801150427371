import React, { ReactElement, ReactNode } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

interface AnimationButtonProps extends IconButtonProps {
    handleClickStartStopAnimation: () => void;
}

type ButtonRef = React.RefObject<HTMLButtonElement>;
const AnimationButtonWithRef: React.ForwardRefRenderFunction<ButtonRef, AnimationButtonProps> = React.forwardRef((
    props,
    ref
) => {
    const { handleClickStartStopAnimation, children, ...other } = props;
    return (
        <IconButton
            ref={ref}
            color="primary"
            aria-label="animate"
            onClick={handleClickStartStopAnimation}
            sx={{
                zIndex: 2,
                "& svg": {
                    fontSize: 'large',
                }
            }}
            {...other} // pass down any other props of IconButton
        >
            {children}
        </IconButton>
    );
});

export default AnimationButtonWithRef;
