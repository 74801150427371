import React, { useState, ReactNode } from 'react'
import { Collapse, Button, Slider, Switch, TextField, Box, FormGroup, FormControlLabel } from "@mui/material";
import CometRotatingBorderInsideElementOnPerimeter from "./CometRotatingBorderInsideElementOnPerimeter.tsx"


type Props = {
    children?: ReactNode;
}

// type DrawerProps = {
//     anchor: "left" | "right" | "top" | "bottom";
//     open: boolean;
//     onClose: () => void;
//     PaperProps?: React.CSSProperties;
// };

const sliderMarks = [
    {
        value: 50,
        label: '50px',
    },
    {
        value: 250,
        label: '250px',
    },
    {
        value: 500,
        label: '500px',
    },
];


const CometWithFormMuiDrawer = (props: Props) => {
    const { children } = props
    const [options, setOptions] = useState({ X: true, Y: true, Z: true });
    const [sizePx, setSizePx] = useState(150);

    const handleWidthChange = (event: any, newValue: number | number[]) => {
        setSizePx(newValue as number);
    };

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOptions({ ...options, [event.target.name]: event.target.checked });
    };

    // This is a state variable to control the drawer visibility
    const [open, setOpen] = useState<boolean>(false);

    const handleDrawerOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen((prevOpen) => {
            const buttons = document.querySelectorAll('.cometSettings');
            buttons.forEach(button => {
                // Check if the button is not the one that was clicked
                if (button !== event.currentTarget) {
                    button.disabled = !prevOpen;
                    if (!prevOpen) {
                        if (!button.classList.contains('Mui-disabled')) {
                            button.classList.add('Mui-disabled');
                        }
                    } else {
                        button.classList.remove('Mui-disabled');
                    }
                }
            });
            return !prevOpen;
        });
    };


    return (
        <Box sx={{ fontSize: ["smaller", "small"], position: "relative" }}>
            <Button
                variant="contained"
                color="primary"
                onClick={handleDrawerOpen}
                className="cometSettings"
                sx={{
                    fontSize: ["small", "small"]
                }}
            >
                Nastavenia
            </Button>
            <Collapse
                in={open}
                sx={{ position: 'absolute', width: '100%', zIndex: 2 }}
                orientation='horizontal'
            >
                <Box bgcolor="background.paper" color="black" sx={{
                    // zIndex: 1, 
                    transform: "translateY(-270px)",
                    width: "300px", height: "230px", maxWidth: "100%",
                    alignContent: 'center'
                }}>
                    {/* <IconButton onClick={handleDrawerClose}>
                        <CloseIcon />
                    </IconButton> */}

                    <FormGroup sx={{ flexDirection: "row", zIndex: "inherit" }} >
                        <FormControlLabel
                            control={
                                <Switch
                                    color="warning"
                                    // defaultChecked
                                    checked={options.X}
                                    onChange={handleOptionChange}
                                    name="X"
                                    inputProps={{ "aria-label": "x axis" }}
                                />
                            }
                            label="X"
                            labelPlacement="top"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    color="success"
                                    // defaultChecked
                                    checked={options.Y}
                                    onChange={handleOptionChange}
                                    name="Y"
                                    inputProps={{ "aria-label": "y axis" }}
                                />
                            }
                            label="Y"
                            labelPlacement="top"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    color="error"
                                    checked={options.Z}
                                    onChange={handleOptionChange}
                                    name="Z"
                                    inputProps={{ "aria-label": "z axis" }}
                                />
                            }
                            label="Z"
                            labelPlacement="top"
                        />
                    </FormGroup>

                    <br />
                    <Box sx={{
                        p: 2, border: '1px dashed grey',
                        display: 'flex', alignItems: 'center',
                        zIndex: "inherit"
                    }}
                    // spacing={2} 
                    >
                        <Slider
                            color="primary"
                            value={sizePx}
                            min={50}
                            max={500}
                            marks={sliderMarks}
                            onChange={handleWidthChange}
                            sx={{
                                flex: 1,
                                mx: "8px",
                                zIndex: "inherit"
                            }}
                        />
                        <TextField
                            label="Width"
                            value={sizePx}
                            onChange={(e) => setSizePx(parseInt(e.target.value))}
                            sx={{
                                width: 60,
                                ml: "1rem",
                                mr: "2px", zIndex: "inherit"
                            }}
                        />
                    </Box>

                    <br />
                    {/* </Drawer> */}
                </Box>
            </Collapse>
            {/* </div> */}
            <CometRotatingBorderInsideElementOnPerimeter
                options={options}
                wrapstyle={{ width: `${sizePx}px`, height: `${sizePx}px`, }}
            >
                {children}
            </CometRotatingBorderInsideElementOnPerimeter>
        </Box>
    );
}

export default CometWithFormMuiDrawer

