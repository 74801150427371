import React, { useRef, useState, ReactNode, ReactElement, CSSProperties, useContext } from 'react'
import { NotInViewContext } from '../AnimateScrollIntoViewport';
// import StopIcon from '@mui/icons-material/Stop';

import * as styles from "./cometRotatingBorderInsideElementOnPerimeter.module.css"
import AnimationButtonWithRef from '../AnimationButtonWithRef';
// import AnimationButton from '../AnimationButton';

import AnimationCycleTimeSlider from '../AnimationCycleTimeSlider';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopCircleIcon from '@mui/icons-material/StopCircle';

interface CometProps {
    children?: ReactNode;
    options?: Record<string, boolean>;
    wrapstyle?: Record<string, string>;
}

interface CometDirProps {
    children?: ReactNode;
    direction: "X" | "Y" | "Z"
}

function CometDimPart({ direction, children }: CometDirProps) {

    return (
        <div className={`${styles?.wrapComet} ${styles?.[`dir${direction}`]}`} >
            <div className={styles?.comet}>
                {children}
                <div className={styles?.circleComet}>
                    <p>doteraz nie</p>
                </div>
            </div>
        </div>)
}


const CometRotatingBorderInsideElementOnPerimeter = ({
    children, options = { X: true, Y: true, Z: true }, wrapstyle = { height: "250px", width: "250px" } }: CometProps): ReactElement => {
    const [duration, setDuration] = useState(3);
    const { notInView, isCountedTwice } = useContext(NotInViewContext);
    const { X, Y, Z } = options
    const ref = useRef<HTMLDivElement>(null)
    const refB = useRef<HTMLButtonElement>(null)

    const [animation, setAnimation] = useState(false);
    const [isClicked, setIsClicked] = useState(false);


    const handleClickStartStopAnimation = (e: React.MouseEvent<HTMLButtonElement>) => {
        !isClicked && setIsClicked(true)
        setAnimation((prev) => !prev)
    }


    const getClassToAnimate = () => {
        switch (true) {
            case isClicked && animation:
                return `${styles.animate}`
            case notInView:
                if (animation) setAnimation(false)
                return ""
            case !isClicked && !notInView && isCountedTwice:
                if (!animation) setAnimation(true)
                return `${styles.animate}`
            default:
                return ""
        }
    }

    return (
        <>
            <div
                ref={ref}
                // className={`wrapxyz ${notInView ? "" : animation ? "" : styles.animate}`}
                className={`wrapxyz ${getClassToAnimate()}`}
                style={{
                    position: "relative",
                    marginLeft: "auto",
                    marginRight: "auto",
                    transformOrigin: "center",
                    ...wrapstyle,
                    "--duration": `${duration}s`
                } as CSSProperties} >
                {
                    X && <CometDimPart direction='X'>
                        {children}
                    </CometDimPart>
                }
                {
                    Y && <CometDimPart direction='Y'>
                        {children}
                    </CometDimPart>
                }
                {
                    Z && <CometDimPart direction='Z'>
                        {children}
                    </CometDimPart>
                }

            </div>
            <AnimationButtonWithRef ref={refB} handleClickStartStopAnimation={handleClickStartStopAnimation} >
                {animation ? <StopCircleIcon /> : <PlayCircleOutlineIcon />}
            </AnimationButtonWithRef>
            <AnimationCycleTimeSlider duration={duration} setDuration={setDuration} />
        </>

    )
}

export default CometRotatingBorderInsideElementOnPerimeter





