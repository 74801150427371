// extracted by mini-css-extract-plugin
export var animate = "cometRotatingBorderInsideElementOnPerimeter-module--animate--26afa";
export var circleComet = "cometRotatingBorderInsideElementOnPerimeter-module--circleComet--f1b14";
export var comet = "cometRotatingBorderInsideElementOnPerimeter-module--comet--99975";
export var dirX = "cometRotatingBorderInsideElementOnPerimeter-module--dirX--03146";
export var dirY = "cometRotatingBorderInsideElementOnPerimeter-module--dirY--ddfb3";
export var dirZ = "cometRotatingBorderInsideElementOnPerimeter-module--dirZ--b03c9";
export var rotateXComet = "cometRotatingBorderInsideElementOnPerimeter-module--rotateXComet--afeaf";
export var rotateYComet = "cometRotatingBorderInsideElementOnPerimeter-module--rotateYComet--67814";
export var rotateZComet = "cometRotatingBorderInsideElementOnPerimeter-module--rotateZComet--8e51e";
export var scaling = "cometRotatingBorderInsideElementOnPerimeter-module--scaling--bbf29";
export var wrapComet = "cometRotatingBorderInsideElementOnPerimeter-module--wrapComet--aa9e4";